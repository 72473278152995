/* dark/light mode  */
function myFunction() {
  var element = document.body;
  element.classList.toggle("dark-mode");
}

/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
var prevScrollpos = window.scrollY;
window.onscroll = function() {
  var currentScrollPos = window.scrollY;
  if (prevScrollpos > currentScrollPos) {
    document.getElementById("nav").style.top = "0";
  } else {
    document.getElementById("nav").style.top = "-72rem";
  }
  prevScrollpos = currentScrollPos;
}

/* parallax JS */
  /* window.addEventListener('scroll', function(event) {
    var depth, i, artlayer, artlayers, len, movement, topDistance, translate3d;
    topDistance = this.pageYOffset;
    artlayers = document.querySelectorAll("[data-type='parallax']");
    for (i = 0, len = artlayers.length; i < len; i++) {
      artlayer = artlayers[i];
      depth = artlayer.getAttribute('data-depth');
      movement = -(topDistance * depth);
      translate3d = 'translate3d(0, ' + movement + 'px, 0)';
      artlayer.style['-webkit-transform'] = translate3d;
      artlayer.style['-moz-transform'] = translate3d;
      artlayer.style['-ms-transform'] = translate3d;
      artlayer.style['-o-transform'] = translate3d;
      artlayer.style.transform = translate3d;
    }
  }); */

/* Hot reloading --stuff reloads in real time */
if (module.hot) {
    module.hot.dispose(function (data) {
      // module is about to be replaced.
      // You can save data that should be accessible to the new asset in `data`
      data.updated = Date.now();
    });
  
    module.hot.accept(function (getParents) {
      // module or one of its dependencies was just updated.
      // data stored in `dispose` is available in `module.hot.data`
      let { updated } = module.hot.data;
    });
  }
